import { DEPORTED_DETAILS } from '@/modules/background_check/bgc-static'
import { BackgroundCheck, Case } from '@/modules/models'
import axios from 'axios'
import _pickBy from 'lodash/pickBy'
import { action, createModule, mutation } from 'vuex-class-component'


const VuexModule = createModule({
	namespaced: 'BackgroundChecks',
	strict: false,
})

export default class BackgroundChecksStore extends VuexModule {
	backgroundChecks: { [key: string]: BackgroundCheck } = {}
	totalItems = 0
	currentPage = 1
	itemsPerPage = 200

	@mutation
	setBackgroundChecks(payload: { items: any[], total: number }) {
		this.backgroundChecks = payload.items.reduce((bgcs, bgc) => ({
			...bgcs, [bgc.id]: new BackgroundCheck(bgc)
		}), {})
		this.totalItems = payload.total
	}

	@mutation
	setPage(page: number) {
		this.currentPage = Math.max(1, page)
	}

	@mutation
	setItemsPerPage(perPage: number) {
		this.itemsPerPage = Math.max(1, perPage)
	}

	@mutation
	updateBackgroundCheck(backgroundCheck: BackgroundCheck){
		this.backgroundChecks[backgroundCheck.id] = backgroundCheck
	}

	@mutation
	removeBackgroundChecks(ids: string[]){
		this.backgroundChecks = _pickBy(this.backgroundChecks, v => !ids.includes(v.id))
	}

	@mutation
	approveBackgroundChecks(backgroundChecks: BackgroundCheck[]){
		const ids = backgroundChecks.map(b => b.id)
		this.backgroundChecks = Object.values(this.backgroundChecks).reduce((bgcs, bgc) => {
			if(ids.includes(bgc.id))
				bgc.status = 'passed'

			return {
				...bgcs,
				[bgc.id]: bgc
			}
		}, {})
	}

	@action
	async loadBackgroundChecks() {
		const { data } = await axios.get('/api/v1/background-checks', { 
			params: { 
				status: 'pending', 
				country_code: 'DO',
				skip: (this.currentPage - 1) * this.itemsPerPage,
				limit: this.itemsPerPage
			}
		})
		this.setBackgroundChecks(data)
	}

	@action
	async sendBackgroundCheck(payload: { id: string, photos: File[] }){
		const { id, photos } = payload

		const formData = new FormData()
		photos.forEach(p => formData.append('files', p))

		let toSend = this.backgroundChecks[id].clone()
		toSend.details.JUD_PENAL.cases = toSend.details.JUD_PENAL.cases.map(c => {
			if(c.deported){
				return new Case({ details: DEPORTED_DETAILS, estado_actual: "Deported", deported: true })
			}

			return c
		})

		const promises = [
			axios.put('/api/v1/background-checks/update_many', [toSend]),
			...photos.length ? [axios.post(`/api/v1/background-checks/${toSend.id}/files`, formData, { headers: {'Content-Type': 'multipart/form-data' } })] : []
		]

		await Promise.all(promises)
		this.removeBackgroundChecks([id])
	}

	@action
	async sendApprovedBackgroundChecks(){
		const approvedToSend = Object.values(this.backgroundChecks).filter(bgc => bgc.status == 'passed').reduce((accumulator: BackgroundCheck[], bgc: BackgroundCheck)=> {
			bgc.details.screening_done = true
			accumulator.push(bgc)
			return accumulator
		}, [])
		await axios.put('/api/v1/background-checks/update_many', approvedToSend)
		this.removeBackgroundChecks(approvedToSend.map(bgc => bgc.id))
	}
}
